@use "sass:map";
@import "../../styles/variables";

$element-article: (
	"couleur-bouton": #f0f
);

.element-article {
	padding: 20px 20px 20px 20px;

	.wrapper {
		cursor: pointer;
		&:hover {
			box-shadow: 2px 2px 3px #ccc;
		}

		.contenu {
			height: 50px;
			background-color: white;

			.bloc {
				display: flex;
				flex-direction: column;
				height: 100%;
				padding: 10px 20px 20px 20px;

				.titre {
					height: 34px;
					//background-color: aqua;
					overflow: hidden;

					h2 {
						font-size: 0.9rem;
						text-align: left;
						color: black;
						max-width: 100%;
						text-overflow: ellipsis;
						overflow: hidden;
					}
				}

				.auteurs {
					//background-color: burlywood;
					overflow: hidden;

					height: 62px;
					position: relative;

					ul {
						font-size: 0.8rem;
						padding: 0px 0px 0px 0px;
						margin: 0px 0px 0px 0px;
						text-align: left;
						font-family: "Lato";
						list-style-type: none;
						position: absolute;
						bottom: 0;
						max-width: 100%;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;

						li {
							max-width: 100%;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}
					}
				}

				.element-bas {
					margin-top: auto;
					//background-color: coral;
					height: 67px;
					overflow: hidden;

					.prix {
						display: block;
						padding: 0px 0px 6px 0px;
						text-align: left;
						color: map.get($element-article, "couleur-bouton");
						font-size: 1.2rem;
						font-weight: 500;
						margin-top: auto;
					}

					button {
						display: block;
						background-color: map.get($element-article, "couleur-bouton");
						border: none;
						color: white;
						text-align: center;
						border-radius: 30px;
						border-radius: 10px;
						padding: 3px;
						margin: auto;
						width: 100%;
						font-weight: 500;
						border: 1px solid map.get($element-article, "couleur-bouton");
						position: relative;
						cursor: pointer;

						.icone {
							position: absolute;
							margin-top: -2px;
						}

						.label {
							padding-left: 18px;
						}

						&:hover {
							color: map.get($element-article, "couleur-bouton");
							//border: 1px solid map.get($element-article, "couleur-bouton");
							background-color: #fff;
							transition: all 0.3s ease-in-out;
						}
					}
				}
			}
		}
	}
}
