@import "bootstrap";
@import "../../styles/variables";
@import "../../styles/Font";

.voteAg {
	h1 {
		color: #414042 !important;
		@extend .pt-5;
		@extend .pb-3;
		@extend .text-center;
		font-size: 1.8em;
	}

	h2 {
		@extend .text-white;
		display: inline;
		font-size: 1.1em;
		@extend .pl-3;
		@extend .pr-3;
		padding-top: 3px;
		padding-bottom: 3px;
		@extend .text-uppercase;
	}

	h3 {
		font-size: 1em;
		color: $mt-bleu;
		@extend .text-uppercase;
	}
	h4 {
		font-size: 1em;
	}
	h5 {
		font-size: 1em;
	}

	ul {
		li {
			font-size: 1.1em !important;
		}
	}

	.voteTitre {
		@extend .text-uppercase;
		@extend .text-center;
		@extend .pt-2;
		@extend .pb-2;
		font-weight: bold;
	}

	.titre4 {
		font-weight: bold;
		text-indent: 40px;
	}
	.titre5 {
		font-weight: bold;
		text-indent: 80px;
	}
	.paragraph4 {
		padding-left: 40px;
	}
	.paragraph5 {
		padding-left: 80px;
	}
}
