@import "bootstrap";
@import "../../styles/variables";
@import "../../styles/Font";

.blocOption {
	min-height: 100px;
	background-color: #e2e3e4;
	border-radius: 30px;
	h3 {
		@extend .pt-2;
		@extend .pb-2;
		@extend .text-center;
	}

	.btn-success,
	.btn-success:hover,
	.btn-success:active,
	.btn-success:visited,
	.btn-success:focus {
		background-color: $mt-orange !important;
		border-color: $mt-orange !important;
		@extend .text-white;
	}

	.btn-success,
	.btn-success:active,
	.btn-success:visited,
	.btn-success:focus {
		background-color: $mt-jaune !important;
		border-color: $mt-jaune !important;
		@extend .text-white;
		@extend .text-uppercase;
	}
}
