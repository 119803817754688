@import "../../styles/variables";
@import "bootstrap";

.slider-background {
	height: 440px;
	// height: 650px;
}

.MuiInputBase-root-14 {
	color: $grey !important;
}
div#ReactBackgroundSlider figure {
	background-attachment: fixed;
	height: 650px;
	background-position: 50% 0px;
	background-size: cover;
}

div#ReactBackgroundSlider figure.figureReactBackgroundSlider {
	width: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	color: transparent;
	background-repeat: no-repeat;
	z-index: -1000;
	margin: 0;
	opacity: 1;
	animation: none;
}

@media (max-width: 960px) {
	.titreSlide > h1 {
		font-size: 300% !important;
	}
}
