@import "bootstrap";
@import "../../styles/variables";

.mt-bouton {
	font-size: 1.5em;
	padding: 20px 60px 20px 60px;
	transition: all 0.3s ease-in-out;

	&:hover {
		background-color: transparent !important;
	}
}
