$texte-noir: #414042;
$texte-noir2: #414042;
// $color-menu: #464547; // site avec des cris de joie
// $color-menu: #009d96; // site Taj
$color-menu: #b11f27; // site mission
$color-menu-transp: rgba(177, 31, 39, 0.6); // site mission
// $color-menu: rgba(177, 31, 39, 0.7); // site mission
$rouge: #b11f27; // site mission
$green: #009d96;
// $green2: #39beb7;
$green2: #ffffff;
$green3: #009c97;
// $green: #39beb7;
$blue: #2d5467;
$blue-light: #9fc4d7;
$blue-fonce: #2f5568;

$bleu-slide: #7cb4d7;
$orange-slide: #e46644;
$jaune-slide: #e3af56;

$grey: #666;
$grey2: #424242;
$grey3: #f6f6f6;
// $grey: #414042;
$grey-light: #e7e6e6;

// nouveau design

// Les couleurs :

$orange: #f2c717;
$bleu: #009d96;
$gris: #e2e3e4;
$gris2: #c9cacc;
$gris3: #414042;
$gris4: #afb1b4;

// fond accueil mt
$gris5: #f4f4f5;
$gris6: #e6e7e7;
$gris7: #dcdedf;
$gris8: #ebebec;
$gris9: #d1d3d4;

$mt-bleu: #7cb4d7; // EXHORTATION
$mt-bleu-turquoise: #39beb7; // PARUTION
$mt-orange: #e46644; // REVUE
$mt-orange-clair: #ff9f1f; // TÉMOIGNAGE
$mt-orange2: #ee7840; //
$mt-jaune: #e3af56;
$mt-jaune-clair: #ffd366; // ALBUM
$mt-rouge: #b11f27; // MÉDITATION DU JOUR
$mt-rose: #ff7359; // LA BIBLE EN 1 AN
$mt-vert: #bee056; // CANTIQUE
$mt-vert-fonce: #87bc3d; // LIVRE
$mt-violet: #b58390; // CONFÉRENCE
$mt-marron: #da9d4c; // MESSAGE À LIRE
