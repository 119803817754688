@import "../../styles/variables";
@import "bootstrap";

.myTooltip {
	margin-top: -30px;
	position: absolute;
	color: #fff;
	padding: 10px;
	background: #b11f27;
	transform: translate3d(-50%, -50%, 0);
	border-radius: 20px;
	pointer-events: none;
	z-index: 1000;
}

.flecheBas {
	margin-left: -10px;
	margin-top: 2px;
	height: 0;
	border-style: solid;
	border-width: 15px 10px 0 10px;
	border-color: #b11f27 transparent transparent transparent;
	z-index: 1001;
	position: absolute;
	display: inline-block;
}

.eglisesMobile {
	& a:hover .eglise {
		background-color: #fff;
		color: #b11f27;
	}
}
