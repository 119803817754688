@import "bootstrap";
@import "../../styles/variables";
@import "../../styles/Font";

.livraison {
	.btn-primary,
	.btn-primary:hover,
	.btn-primary:active,
	.btn-primary:visited,
	.btn-primary:focus {
		background-color: $mt-vert-fonce !important;
		border-color: $mt-vert-fonce !important;
		color: #fff !important;
	}

	.btn-primary,
	.btn-primary:active,
	.btn-primary:visited,
	.btn-primary:focus {
		background-color: #39beb7 !important;
		border-color: #39beb7 !important;
		color: #fff !important;
		@extend .text-uppercase;
	}
}
