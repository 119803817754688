@import "bootstrap";
@import "../../styles/variables";

.downloadFiles {
	h1 {
		color: #414042;
		@extend .pt-5;
		@extend .pb-5;
		@extend .text-center;
		font-size: 1.8em;
	}

	h2 {
		@extend .text-white;
		display: inline;
		font-size: 1.1em;
		@extend .pl-3;
		@extend .pr-3;
		padding-top: 3px;
		padding-bottom: 3px;
		@extend .text-uppercase;
	}

	h3 {
		font-size: 1em;
		color: $mt-bleu;
		@extend .text-uppercase;
	}

	ul {
		padding-inline-start: 20px;

		li {
			font-size: 1.1em !important;
			// list-style: none;
		}
	}

	a {
		color: #414042;
		font-weight: bolder;
	}

	.telechargementComplet {
		hr {
			margin-top: 0;
		}

		&:hover {
			a {
				text-decoration: none;
			}

			.select {
				background-color: #e2e3e4;
			}
		}
	}
}
