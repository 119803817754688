@import "../../styles/variables";
@import "bootstrap";

.connect-language img {
	height: 25px;
	margin-top: 4px;
}

.connect-language span {
	text-transform: uppercase;
	font-size: 20px;
	line-height: 38px;
}

.connect-language span.auth,
.connect-language span.auth a {
	// color: $color-menu;
	color: $grey !important;
}

.connect-language a:hover span {
	text-decoration: underline;
}

.connect-language div * {
	float: left;
}

.connect-language span.connected {
	color: $green;
}
