@import "bootstrap";
@import "../../styles/variables";
@import "../../styles/Font";

.formulaireInformations {
	.avatar {
		img:hover {
			opacity: 80%;
		}
	}

	.montantDonInput {
		background-color: $mt-vert-fonce !important;
		@extend .text-white;
		position: relative;
		padding: 0 !important;
		margin: 0 !important;

		height: 40px;
		font-size: 1.7em;
	}

	.euro {
		font-size: 2em;
		margin-top: -7px;
		padding-left: 10px;
	}

	.btn-primary,
	.btn-primary:hover,
	.btn-primary:active,
	.btn-primary:visited,
	.btn-primary:focus {
		background-color: $mt-vert-fonce !important;
		border-color: $mt-vert-fonce !important;
		color: #fff !important;
	}

	.btn-primary,
	.btn-primary:active,
	.btn-primary:visited,
	.btn-primary:focus {
		background-color: #39beb7 !important;
		border-color: #39beb7 !important;
		color: #fff !important;
		@extend .text-uppercase;
	}
}
