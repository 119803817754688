.bibleEnUnAn {
	& tr:hover {
		background-color: #ccc;
	}
	& a:hover {
		text-decoration: none;
	}
}

.mt-BlockLecteurAudio-banner {
	-webkit-background-size: cover;
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
	height: 400px;
	position: relative;
	-webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
	clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
	overflow: hidden;
	// filter: brightness(35%);
	z-index: 1;
}
