.affichageCantiques:hover {
	.select {
		background-color: #e2e3e4;
	}
}

.sui-layout {
	.sui-layout-header {
		padding: 0;
		border-bottom: 0;
	}

	.sui-layout-body {
		background-color: #fff !important;
	}
}

.sui-select__control {
	background-color: #009d96 !important;

	border: #009d96 !important;
	border-radius: 0px !important;
	color: #fff !important;
}

.sui-select__single-value {
	color: #000 !important;
}

.sui-multi-checkbox-facet {
	color: #000 !important;
}

.sui-multi-checkbox-facet__option-count {
	color: #000 !important;
}

.sui-facet-search__text-input {
	border: #009d96 !important;
	border-radius: 0px !important;
	//color: #fff !important;
}

.sui-multi-checkbox-facet__checkbox:checked {
	color: #009d96 !important;
}

.sui-facet-view-more {
	color: #b01125 !important;
	text-indent: -9999px;
	line-height: 0 !important;
	font-weight: bold;

	&:hover {
		// background: #c9cacc !important;
		// padding: 0 !important;
		// margin: 0 !important;
	}

	&::after {
		content: "+ voir plus";
		text-indent: 0;
		display: block;
		line-height: initial;
	}
}

.sui-facet__title {
	color: #009d96 !important;
	font-weight: bold;
}

.sui-search-box__text-input {
	padding: 10px !important;

	&:focus {
		border-top: 1px solid #009d96 !important;
		border-left: 1px solid #009d96 !important;
		border-right: 1px solid #009d96 !important;
		border-bottom: 1px solid #009d96 !important;
		box-shadow: #009d952d 0px 1px 1px !important;
	}
}

.sui-search-box__submit {
	background: #e3af56 !important;
	border-color: #e3af56 !important;
	padding: 10px !important;

	padding: 10px !important;

	&:hover {
		background: #e46644 !important;
		border-color: #e46644 !important;
	}
}

.sui-paging {
	@media (min-width: 576px) {
		// margin: auto;
		// width: 34%;
		// padding-left: 10px;
	}

	@media (min-width: 0px) and (max-width: 640px) {
		//  margin: auto;
		//  width: 50%;
		//  padding-left: 10px;
	}
}

.paging-info {
	display: inline-block;
	color: #666;
	padding-top: 5px;
}

.sui-results-per-page {
	width: 100px;
	display: inline;
	float: right;
}

.sui-results-per-page__label {
	text-indent: -9999px;
	line-height: 0 !important;

	&::after {
		color: #666;
		content: "afficher" !important;
		text-indent: 0;
		display: block;
		line-height: initial;
		font-size: 1.2em;
	}
}
