.mt-pub-banner {
	-webkit-background-size: cover;
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
	height: 400px;
	position: relative;
	-webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
	clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
	overflow: hidden;
	// filter: brightness(35%);
	z-index: 1;
}

.mt-pub-banner-button {
	background-color: #e46644;
	border-color: #eb7061;
	font-size: 1.5em;
	color: #fff;
	border: 1px solid #e46644;
	padding: 20px 60px 20px 60px;
	transition: all 0.3s ease-in-out;
}
.mt-pub-banner-button:hover {
	background-color: transparent;
	color: #e46644;
}
