@import "bootstrap";
@import "../../styles/variables";

.article-revue {
	// p {
	//     margin-top: 0;
	//     margin-bottom: 1rem;
	//     text-align: justify;
	//     font-size: 1.1em;
	// }

	.nom-revue {
		a {
			color: #000;
		}
	}

	img {
		@extend .img-fluid;
		margin: auto;
		display: block;
		max-width: 430px;
		max-height: 295px;
		width: auto;
		height: auto;
	}

	h1 {
		color: #414042;
		// @extend .pt-5;
		// @extend .pb-5;
		@extend .text-center;
		//font-size: 1.8em;
		font-size: 1.4em;
	}

	h2 {
		//@extend .text-white;
		display: inline;
		font-size: 1.1em;
		// @extend .pl-3;
		@extend .pb-3;
		padding-top: 10px;
		//padding-bottom: 3px;
		@extend .text-uppercase;
	}

	h3 {
		font-size: 1em;
		//color: $mt-bleu;
		@extend .text-uppercase;
		padding-top: 10px;
	}

	.bas-de-page {
		font-size: 0.9em !important;
		list-style: none;
		li {
			padding-top: 18px;
		}
		padding-left: 0px;
	}

	ul {
		li {
			font-size: 1.1em !important;
		}
	}

	.telechargementComplet {
		@extend .pb-5;
		@extend .pt-5;

		hr {
			margin-top: 0;
		}

		&:hover {
			a {
				text-decoration: none;
			}

			.select {
				background-color: #e2e3e4;
			}
		}
	}

	sup {
		:hover {
		}
	}

	.telechargementComplet {
		@extend .pb-5;
		@extend .pt-5;
		hr {
			margin-top: 0;
		}
		&:hover {
			a {
				text-decoration: none;
			}
			.select {
				background-color: #e2e3e4;
			}
		}
	}
}
