.bibleEnUnAnLecture {
	& a:hover {
		text-decoration: none;
	}
	& .play2 {
		opacity: 0;
	}

	& .enLecture .sound {
		display: inline;
	}

	& .sound {
		display: none;
		position: absolute;
		margin-top: -8px;
		margin-left: -10px;
	}

	.texteDesc {
		margin-left: 25px;
	}

	@media (min-width: 500px) {
		& .sound {
			margin-left: 0px;
		}

		tr:hover {
			background-color: #eee;
			.play2 {
				opacity: 1;
			}
			.sound {
				display: none;
			}
			&.enLecture {
				background-color: #fff;
				cursor: auto;
				.play2 {
					opacity: 0;
				}
				.sound {
					display: inline;
				}
			}
		}

		.texteDesc {
			margin-left: 70px;
		}
	}
}
