.reunionsDirect li {
	list-style: none;
}

audio {
	outline: none;
}

.video-container {
	overflow: hidden;
	padding-bottom: 56.25%;
	position: relative;
	height: 0;
}

.video-container iframe {
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	position: absolute;
}

.boutonA {
	// color: #007bff;
	color: #007bff;
	text-decoration: none;
	background-color: transparent;
	cursor: pointer;
	&:hover {
		color: #0056b3;
		// color: #0056b3;
		text-decoration: underline;
	}
}

.modalAlbumCd {
	.modal-dialog {
		max-width: 900px;
		margin: 30px auto;
	}

	.modal-body {
		position: relative;
		padding: 0px;
	}
	.close {
		position: absolute;
		right: -30px;
		top: 0;
		z-index: 999;
		font-size: 2rem;
		font-weight: normal;
		color: #fff;
		opacity: 1;
	}
}
