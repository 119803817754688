.media-revue {
	ul {
		//list-style: none;
		//padding-left: 0px;
		li {
			padding-top: 10px;
			a {
				color: #414042;
			}
		}
	}
}
