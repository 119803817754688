@import "../../styles/variables";

.header-recherche {
	.breadcrumb-wrapper {
		display: inline-block;
	}

	.terme-wrapper {
		text-align: center;
		display: inline-block;
		position: relative;
		font-size: 1em;
		font-weight: 500;

		.terme-val {
			text-align: center;
			display: inline-block;
			border: 1px solid #e72385;
			padding-left: 20px;
			border-radius: 9px;
			color: #fff;
			background: #e72385;
			padding-right: 40px;
			padding-top: 2px;
			margin-left: 5px;
		}
		.icone {
			position: absolute;
			right: 14px;
			top: 2px;
			padding-left: 5px;
			cursor: pointer;
			display: inline-block;
		}
	}
}

.sui-layout {
	.sui-layout-header {
		padding: 0;
		border-bottom: 0;
	}

	.sui-layout-body {
		background-color: #fff !important;
	}
}

.sui-select__control {
	background-color: #288a93 !important;

	border: #288a93 !important;
	border-radius: 0px !important;
	color: #fff !important;
}

.sui-select__single-value {
	color: #000 !important;
}

.sui-multi-checkbox-facet {
	color: #000 !important;
}

.sui-multi-checkbox-facet__option-count {
	color: #000 !important;
}

.sui-facet-search__text-input {
	border: #288a93 !important;
	border-radius: 0px !important;
	//color: #fff !important;
}

.sui-multi-checkbox-facet__checkbox:checked {
	color: #288a93 !important;
}

.sui-facet-view-more {
	//color: $ljc-rose !important;
	text-indent: -9999px;
	line-height: 0 !important;
	font-weight: bold;

	&::after {
		content: "+ voir plus";
		text-indent: 0;
		display: block;
		line-height: initial;
	}
}

.sui-facet__title {
	color: #288a93 !important;
	font-weight: bold;
}

.sui-search-box__text-input {
	padding: 10px !important;

	&:focus {
		border-top: 1px solid #288a93 !important;
		border-left: 1px solid #288a93 !important;
		border-right: 1px solid #288a93 !important;
		border-bottom: 1px solid #288a93 !important;
		box-shadow: #009d952d 0px 1px 1px !important;
	}
}

.sui-search-box__submit {
	background-color: #e3af56 !important;
	border-color: #e3af56 !important;
	padding: 10px !important;
}

.sui-paging {
	@media (min-width: 576px) {
		// margin: auto;
		// width: 34%;
		// padding-left: 10px;
	}

	@media (min-width: 0px) and (max-width: 640px) {
		//  margin: auto;
		//  width: 50%;
		//  padding-left: 10px;
	}
}

.paging-info {
	display: inline-block;
	color: #666;
	padding-top: 5px;
}

.sui-select__single-value {
	color: #fff !important;
}

.result-par-page {
	.sui-results-per-page {
		width: 100px;
		display: inline;
		float: right;
		padding-right: 25px;
		margin-right: -40px;
	}

	.sui-select__single-value {
		color: #fff !important;
	}

	.sui-results-per-page__label {
		text-indent: -9999px;
		line-height: 0 !important;

		// &::after {
		// 	color: #666;
		// 	content: "afficher" !important;
		// 	text-indent: 0;
		// 	display: block;
		// 	line-height: initial;
		// 	font-size: 1.2em;
		// }
	}
}

.sui-select__control {
	cursor: pointer;
}
